import React, { useState } from "react";
import {
  Box,
  Flex,
  Image,
  Button,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Collapse,
  IconButton,
  useBreakpointValue,
  textDecoration,
} from "@chakra-ui/react";
import { ChevronDownIcon, HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import logo from "../assets/uniq-logo.png";
import { Link as RouterLink } from "react-router-dom";

const Header = () => {
  // State to control dropdown visibility and mobile menu visibility
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  // Determine when to show the menu button based on the breakpoint
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box bg="white" px={4} shadow="md">
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <RouterLink to="/erp">
          <Image
            src={logo}
            alt="Your Logo"
            boxSize="100px"
            objectFit="contain"
            mx={9}
            cursor="pointer"
          />
        </RouterLink>
        <Flex alignItems="center">
          {/* Menu Button for Mobile View */}
          {isMobile && (
            <IconButton
              icon={isMenuOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label="Toggle Menu"
              onClick={() => setMenuOpen(!isMenuOpen)}
              variant="outline"
              mr={2}
            />
          )}

          {/* Solutions Dropdown for Desktop View */}
          {!isMobile && (
            <Menu>
              <MenuButton
                as={Button}
                variant="link"
                mx={4}
                rightIcon={<ChevronDownIcon />}
                onMouseEnter={() => setDropdownOpen(true)}
                onMouseLeave={() => setDropdownOpen(false)}
                color="black"
                _hover={{
                  textDecoration: "none",
                  color: "teal.500",
                  transform: "scale(1.05)",
                }}
              >
                Solutions
              </MenuButton>
              <MenuList
                onMouseEnter={() => setDropdownOpen(true)}
                onMouseLeave={() => setDropdownOpen(false)}
              >
                {[
                  "Financial Accounting",
                  "Order Management",
                  "HR and Payroll",
                  "Manufacturing",
                  "CRM",
                  "Projects",
                  "Helpdesk",
                  "Asset Management",
                ].map((item) => (
                  <MenuItem key={item}>
                    <Link
                      href={`/erp/${item.replace(/\s+/g, "-").toLowerCase()}`}
                      textDecoration="none"
                      _hover={{
                        textDecoration: "none",
                        color: "teal.500",
                        transform: "scale(1.05)",
                      }}
                    >
                      {item}
                    </Link>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          )}

          {/* Other Links for larger screens */}
          <Flex display={{ base: "none", md: "flex" }}>
            {/* {["Services", "Partners", "About", "Pricing"].map((item) => ( */}
            {["Pricing"].map((item) => (
              <Link
                key={item}
                href={`/erp/${item.toLowerCase()}`}
                mx={4}
                fontWeight="medium"
                _hover={{
                  textDecoration: "none",
                  color: "teal.500",
                  transform: "scale(1.05)",
                }}
              >
                {item}
              </Link>
            ))}
          </Flex>

          {/* Contact Us Button */}
          <Button
            ml={4}
            backgroundColor="black"
            color="white"
            border="2px solid black"
            _hover={{
              backgroundColor: "white",
              color: "black",
              transform: "scale(1.05)",
            }}
            transition="0.3s"
            display={{ base: "none", md: "block" }} // Only show on larger screens
            onClick={() =>
              window.open("https://calendly.com/ashish-avapadvisory", "_blank")
            }
          >
            Contact Us
          </Button>
        </Flex>
      </Flex>

      {/* Collapsible Menu for Mobile View */}
      <Collapse in={isMenuOpen}>
        <Box pb={4} display={{ md: "none" }} mx={4}>
          <Flex direction="column" alignItems="flex-start">
            {/* Solutions in mobile view */}
            <Menu>
              <MenuButton
                as={Button}
                variant="link"
                my={2}
                color="black"
                rightIcon={<ChevronDownIcon />}
                onClick={() => setDropdownOpen(!isDropdownOpen)}
              >
                Solutions
              </MenuButton>
              <MenuList>
                {[
                  "Financial Accounting",
                  "Order Management",
                  "HR and Payroll",
                  "Manufacturing",
                  "CRM",
                  "Projects",
                  "Helpdesk",
                  "Asset Management",
                ].map((item) => (
                  <MenuItem key={item}>
                    <Link
                      href={`/erp/${item.replace(/\s+/g, "-").toLowerCase()}`}
                      textDecoration="none"
                    >
                      {item}
                    </Link>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>

            {/* Other Links */}
            {["Pricing"].map((item) => (
              <Link
                key={item}
                href={`/erp/${item.toLowerCase()}`}
                my={2}
                color="black"
              >
                {item}
              </Link>
            ))}

            {/* Contact Us in mobile view */}
            <Button
              backgroundColor="black"
              color="white"
              border="2px solid black"
              my={2}
              onClick={() =>
                window.open(
                  "https://calendly.com/ashish-avapadvisory",
                  "_blank"
                )
              }
            >
              Contact Us
            </Button>
          </Flex>
        </Box>
      </Collapse>
    </Box>
  );
};

export default Header;