import React from "react";
import { Box, Text } from "@chakra-ui/react";
import Spline from "@splinetool/react-spline";
import DoneIcon from "@mui/icons-material/Done";

function ComingSoon() {
  return (
    <div className="relative w-full flex flex-col   pt-28   items-center min-h-[100dvh] bg-[rgb(15,15,15)]  text-white">
      <div className="  absolute top-0 w-full h-[100dvh] flex flex-col gap-12   items-center">
        <Spline
          scene="https://prod.spline.design/QViPyjcaj2HO8zRf/scene.splinecode"
          className=" absolute max-w-[100dvw] max-h-[120dvh] z-0 top-0"
        />
        <div className=" absolute h-[40px] w-[180px] rounded-l-2xl right-0 bottom-[18px] z-10 bg-[rgb(15,15,15)]"></div>
        <div className=" absolute h-[40px] w-[140px] rounded-l-2xl right-0 bottom-[76px] z-10 bg-[rgb(15,15,15)]"></div>
        <div className=" absolute h-[40px] w-[100px] rounded-l-2xl right-0 bottom-[134px] z-10 bg-[rgb(15,15,15)]"></div>
        <div className=" absolute h-[40px] w-[180px] rounded-r-2xl left-0 bottom-[18px] z-10 bg-[rgb(15,15,15)]"></div>
        <div className=" absolute h-[40px] w-[140px] rounded-r-2xl left-0 bottom-[76px] z-10 bg-[rgb(15,15,15)]"></div>
        <div className=" absolute h-[40px] w-[100px] rounded-r-2xl left-0 bottom-[134px] z-10 bg-[rgb(15,15,15)]"></div>
      </div>

      <div className=" z-10 translate-x-60 mb-40  tracking-wide flex flex-col gap-5">
        <span className=" text-[6rem] w-[500px] leading-[6rem]  font-bold">
          {"Build your dreams with AI!"}
        </span>
        <span className=" text-[1.5rem] font-light">
          {"Your Vision. Your Product. Our AI just build it."}
        </span>
        <span className=" rounded-[100px] text-[1.5rem] font-light bg-white text-black  w-[300px] flex justify-center px-5 py-4">
          {" "}
          {"Start Building Today!"}
        </span>
      </div>

      <div className=" flex justify-center pb-14 bg-gradient-to-b from-[rgb(15,15,15)] via-[rgba(15,15,15,0.4)] to-cyan-800 gap-48 items-center w-full relative z-10">
        <div className=" flex p-5 gap-20 flex-col  font-bold tracking-wider text-[2rem] w-[400px]">
          <span>{"No tech knowledge needed"}</span>
          <span>{"AI makes it fast and cost-effective"}</span>
          <span>{"with a dedicated human expert always"}</span>
        </div>


        

        <div className=" flex relative  p-5 gap-5 flex-col min-h-[80dvh] tracking-wider text-[2rem] w-[50vw]">
          <span className="absolute flex items-center justify-center shadow-[-5px_-5px_0px_0px_rgba(200,200,200)] top-[100px]  before:content-['+'] before:rounded-[50%] before:p-4 before:pr-12 before:pb-12 before:w-[60px] before:h-[60px] before:translate-x-[40%] before:text-[3rem] before:font-bold before:translate-y-[-40%] before:bg-green-600 before:absolute before:top-0 before:leading-6 before:right-0 before:z-10   rounded-md  left-[70px] bg-white w-[260px] h-[260px]">
            <img
              src="/modules/1.png"
              alt="module"
              className="w-[200px] h-[200px] "
            />
          </span>
          <span className="absolute flex items-center justify-center shadow-[-5px_-5px_0px_0px_rgba(200,200,200)] top-[20px]  before:content-['+'] before:rounded-[50%] before:p-4 before:pr-12 before:pb-12 before:w-[60px] before:h-[60px] before:translate-x-[40%] before:text-[3rem] before:font-bold before:translate-y-[-40%] before:bg-green-600 before:absolute before:top-0 before:leading-6 before:right-0 before:z-10 rounded-md right-[70px] bg-white w-[260px] h-[260px]">
            <img
              src="/modules/2.png"
              alt="module"
              className="w-[200px] h-[200px] "
            />{" "}
          </span>
          <span className="absolute flex items-center justify-center shadow-[-5px_-5px_0px_0px_rgba(200,200,200)] bottom-[20px]  before:content-['+'] before:rounded-[50%] before:p-4 before:pr-12 before:pb-12 before:w-[60px] before:h-[60px] before:translate-x-[40%] before:text-[3rem] before:font-bold before:translate-y-[-40%] before:bg-green-600 before:absolute before:top-0 before:leading-6 before:right-0 before:z-10 rounded-md left-0 bg-white w-[260px] h-[260px]">
            <img
              src="/modules/3.png"
              alt="module"
              className="w-[200px] h-[200px] "
            />{" "}
          </span>
          <span className="absolute flex items-center justify-center shadow-[-5px_-5px_0px_0px_rgba(200,200,200)] bottom-[100px] before:content-['+'] before:rounded-[50%] before:p-4 before:pr-12 before:pb-12 before:w-[60px] before:h-[60px] before:translate-x-[40%] before:text-[3rem] before:font-bold before:translate-y-[-40%] before:bg-green-600 before:absolute before:top-0 before:leading-6 before:right-0 before:z-10 rounded-md  right-0 bg-white w-[260px] h-[260px]">
            <img
              src="/modules/4.png"
              alt="module"
              className="w-[200px] h-[200px] "
            />{" "}
          </span>
        </div>
      </div>

      <div className=" flex justify-center pt-40 pb-10 bg-[rgb(252,248,245)] gap-10 items-center flex-col w-full relative z-10">
        <div className=" w-[80%]  gap-20 bg-[#5e11da] rounded-lg flex flex-col items-center p-4">
          <span className=" tracking-widest font-light bg-white w-[250px] text-center text-black text-[1.5rem] px-5 py-3 ">
            {"I want to build a"}
          </span>

          <div className=" flex justify-around w-[120%] ">
            <div className=" max-w-[260px] text-black flex flex-col justify-between p-6 gap-4 min-h-[260px] bg-white rounded-2xl border-[2px] border-black ">
              <span className="text-[1.4rem] font-bold">{"Builder Cloud"}</span>
              <span className=" ">
                {
                  "Benefits from guranteed savings on your cloud hosting every month"
                }
              </span>
              <span className="relative w-[85px] after:content-[''] after:absolute after:w-full after:border-[1px] after:left-0 after:border-green-500 after:bottom-0 ">
                {"Learn More"}
              </span>
            </div>
            <div className=" max-w-[260px] text-black flex flex-col justify-between p-6 gap-4 min-h-[260px] bg-white rounded-2xl border-[2px] border-black ">
              <span className="text-[1.4rem] font-bold">{"Builder Cloud"}</span>
              <span className=" ">
                {
                  "Benefits from guranteed savings on your cloud hosting every month"
                }
              </span>
              <span className="relative w-[85px] after:content-[''] after:absolute after:w-full after:border-[1px] after:left-0 after:border-green-500 after:bottom-0 ">
                {"Learn More"}
              </span>
            </div>
            <div className=" -translate-y-12 max-w-[260px] text-black flex flex-col justify-between p-6 gap-4 min-h-[260px] bg-white rounded-2xl border-[2px] border-black ">
              <span className="text-[1.4rem] font-bold">{"Builder Cloud"}</span>
              <span className=" ">
                {
                  "Benefits from guranteed savings on your cloud hosting every month"
                }
              </span>
              <span className="relative  w-[200px] text-center bg-green-600 text-white font-bold border-[4px] rounded-full px-4 py-2 ">
                {"Build"}
              </span>
            </div>
            <div className=" max-w-[260px] text-black flex flex-col justify-between p-6 gap-4 min-h-[260px] bg-white rounded-2xl border-[2px] border-black ">
              <span className="text-[1.4rem] font-bold">{"Builder Cloud"}</span>
              <span className=" ">
                {
                  "Benefits from guranteed savings on your cloud hosting every month"
                }
              </span>
              <span className="relative w-[85px] after:content-[''] after:absolute after:w-full after:border-[1px] after:left-0 after:border-green-500 after:bottom-0 ">
                {"Learn More"}
              </span>
            </div>
            <div className=" max-w-[260px] text-black flex flex-col justify-between p-6 gap-4 min-h-[260px] bg-white rounded-2xl border-[2px] border-black ">
              <span className="text-[1.4rem] font-bold">{"Builder Cloud"}</span>
              <span className=" ">
                {
                  "Benefits from guranteed savings on your cloud hosting every month"
                }
              </span>
              <span className="relative w-[85px] after:content-[''] after:absolute after:w-full after:border-[1px] after:left-0 after:border-green-500 after:bottom-0 ">
                {"Learn More"}
              </span>
            </div>
          </div>
        </div>
        <span className=" rounded-[100px] text-[1.5rem] border-[2px] border-black font-light bg-white text-black  w-[300px] flex justify-center px-5 py-4">
          {" "}
          {"Start Building Today!"}
        </span>
      </div>

      <div className=" flex justify-center pt-20 pb-20 bg-[rgb(246,241,237)] gap-10 items-center flex-col w-full relative z-10">
        <div className=" w-[80%]  gap-20 bg-white rounded-lg flex flex-row  justify-center p-10">
          <div className=" w-[50%]  gap-14  rounded-lg flex flex-col items-start p-4">
            <span className=" before:content-['1'] before:bg-[#5e11da] before:text-white before:px-3 before:py-2 before:mr-4 before:rounded-md before:text-[1.5rem] before:font-bold text-black font-bold text-[1.2rem]">
              {"Chat to our AI Natasha"}
            </span>
            <span className=" before:content-['2'] before:bg-[#5e11da] before:text-white before:px-3 before:py-2 before:mr-4 before:rounded-md before:text-[1.5rem] before:font-bold text-black font-bold text-[1.2rem]">
              {"Chat to our AI Natasha"}
            </span>
            <span className=" before:content-['3'] before:bg-[#5e11da] before:text-white before:px-3 before:py-2 before:mr-4 before:rounded-md before:text-[1.5rem] before:font-bold text-black font-bold text-[1.2rem]">
              {"Chat to our AI Natasha"}
            </span>
            <span className=" before:content-['4'] before:bg-[#5e11da] before:text-white before:px-3 before:py-2 before:mr-4 before:rounded-md before:text-[1.5rem] before:font-bold text-black font-bold text-[1.2rem]">
              {"Chat to our AI Natasha"}
            </span>
            <span className=" before:content-['5'] before:bg-[#5e11da] before:text-white before:px-3 before:py-2 before:mr-4 before:rounded-md before:text-[1.5rem] before:font-bold text-black font-bold text-[1.2rem]">
              {"Chat to our AI Natasha"}
            </span>
            <span className=" before:content-['6'] before:bg-[#5e11da] before:text-white before:px-3 before:py-2 before:mr-4 before:rounded-md before:text-[1.5rem] before:font-bold text-black font-bold text-[1.2rem]">
              {"Chat to our AI Natasha"}
            </span>
          </div>
          <div className=" w-[50%]  gap-20 bg-[#5e11da] rounded-lg flex flex-col items-center p-4"></div>
        </div>
      </div>

      <div className=" flex justify-center pt-20 pb-20 bg-[rgb(252,248,245)] gap-10 items-center flex-col w-full relative z-10">
        <div className=" w-[80%] text-black  gap-10 bg-white rounded-lg flex flex-col  items-center p-10">
          <span className=" font-bold text-[2.5rem] ">
            {"An app platform for every type of business"}
          </span>
          <div className="  text-black w-full  gap-20 bg-white rounded-lg flex flex-row  justify-center "></div>
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
