import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Heading,
  Text,
  Stack,
  Image,
  Flex,
  Switch,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import customizations from "../assets/customize-sales-invoice.png";
import lifecycle from "../assets/assets-list-view.png";
import inventory from "../assets/asset-item-master.png";
import purchasing from "../assets/purchasing-assets.png";
import selling from "../assets/selling-assets.png";
import depreciation from "../assets/asset-depreciation.png";
import scrapping from "../assets/scrapping-assets.png";
import valueadjustment from "../assets/asset-value-adjustment.png";
import reporting from "../assets/asset-depreciation-report.png";
import "./ModalAnimations.css";

// const AssetManagement = ({ isOpen, onClose, title }) => {
const AssetManagement = () => {
  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.classList.add("no-scroll");
  //   } else {
  //     document.body.classList.remove("no-scroll");
  //   }
  //   return () => {
  //     document.body.classList.remove("no-scroll");
  //   };
  // }, [isOpen]);
  const [showAdvancedText, setShowAdvancedText] = useState(true);
  const toggleText = () => setShowAdvancedText(!showAdvancedText);
  const [showReportText, setShowReportText] = useState(true);
  const toggleReportText = () => setShowReportText(!showReportText);
  return (
    <>
      {/* <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent
            maxW="80vw" // Maximum width: 80% of the viewport width
            maxH="80vh" // Maximum height: 80% of the viewport height
            width="80vw" // Set width to 80% of the viewport width
            height="80vh" // Set height to 80% of the viewport height
            className={isOpen ? "modal-open" : "modal-close"}
          >
            <ModalCloseButton
              position="fixed" // Make the close button fixed
              top="10px" // Adjust the top position as needed
              right="10px" // Adjust the right position as needed
              zIndex="1"
            />
            <ModalBody overflowY="auto"> */}
      <Flex
        justify="flex-start"
        align="center"
        py={0}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
        border={showAdvancedText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showAdvancedText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          // ref={assetRef}
        >
          <Heading fontSize={{ base: "2xl", md: "4xl" }} mb={4}>
            Simplify asset maintenance with the #1 open-source asset management
            tool.
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showAdvancedText
              ? "Allow AI to help you forecast your assets requirements"
              : "Manage all your assets—from purchase to disposal—in one place with uniQ ERP's feature-packed solution."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showAdvancedText}
                onChange={toggleText}
              />
            </FormControl>
          </Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={lifecycle}
            alt="Asset Lifecycle"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Keep track of your assets through their entire lifecycle.
          </Heading>
          <Text fontSize="lg" mb={6}>
            View all asset details in one place—from status and location to
            warranty, depreciation, and transfers—for full transparency.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Easily manage your asset inventory.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Track assets quickly with integrated inventory, barcode scanning,
            and automatic record creation for purchased assets.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={inventory}
            alt="Asset Inventory"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={purchasing}
            alt="Purchasing Assets"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Simplify asset procurement.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Automatically create asset records upon purchase and easily update
            depreciation, insurance, and other details with a few clicks.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Effortlessly sell assets you no longer need.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Quickly sell unused assets and let the system handle accounting
            entries automatically.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={selling}
            alt="Selling Assets"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={depreciation}
            alt="Automating Asset Depreciation"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Automate asset depreciation to save time.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Automatically calculate and record asset depreciation, saving time
            on manual entries.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Easily scrap assets with one click.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Scrap assets effortlessly with automatic accounting entries to keep
            everything seamless.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={scrapping}
            alt="Scrapping Assets"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={valueadjustment}
            alt="Asset Value Adjustment"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Adjust asset values with ease.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Adjust asset values and automatically update depreciation with just
            a few clicks.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
        border={showReportText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showReportText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Gain insights with asset reports.
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showReportText
              ? "Generate reports on the fly by just letting the AI know what you want and when you want - all through text or voice"
              : "Use standard and custom reports to monitor asset performance and make informed decisions."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showReportText}
                onChange={toggleReportText}
              />
            </FormControl>
          </Stack>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={reporting}
            alt="Asset Reporting"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={customizations}
            alt="On-The-Fly Customizations"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            On-The-Fly Customizations
          </Heading>
          <Text fontSize="lg" mb={6}>
            Create your own open source asset management software. Map the data
            you care about by adding custom fields in your forms. Then customize
            form behavior by auto-fetching values, hide fields based on user
            roles, and create custom print formats — all without a single line
            of code.
          </Text>
        </Box>
      </Flex>
      {/* </ModalBody>
          </ModalContent>
        </Modal> */}
    </>
  );
};

export default AssetManagement;
