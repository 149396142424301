import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Heading,
  Text,
  Button,
  Stack,
  Image,
  Flex,
  SimpleGrid,
  Icon,
  Switch,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import {
  FaFileInvoiceDollar,
  FaChartLine,
  FaBook,
  FaFileAlt,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"; // Import Framer Motion for animations
import { useInView } from "react-intersection-observer"; // For triggering animations when in view
import accountsdashboard from "../assets/accounts-dashboard.png";
import chartofaccounts from "../assets/chart-of-accounts.png";
import generalledger from "../assets/general-ledger.png";
import salesinvoice from "../assets/sales-invoice.png";
import multicurrency from "../assets/multi-currency-accounting.png";
import autoinvoice from "../assets/subscription-plan.png";
import banking from "../assets/banking.png";
import customizations from "../assets/customize-sales-invoice.png";
import ChartOfAccounts from "./ChartOfAccounts";
import JournalEntry from "./JouranalEntry";
import Bookkeeping from "./Bookkeeping";
import OrderManagement from "./OrderManagement";
import "./ModalAnimations.css";

const MotionBox = motion(Box);
// const FinancialAccounting = ({ isOpen, onClose, title }) => {
const FinancialAccounting = () => {
  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.classList.add("no-scroll");
  //   } else {
  //     document.body.classList.remove("no-scroll");
  //   }
  //   return () => {
  //     document.body.classList.remove("no-scroll");
  //   };
  // }, [isOpen]);
  // const [openModal, setOpenModal] = useState(""); // Track which modal is open

  // const handleModalOpen = (modalName) => {
  //   setOpenModal(modalName);
  // };

  // const handleModalClose = () => {
  //   setOpenModal("");
  // };

  const [showAdvancedText, setShowAdvancedText] = useState(true);
  const [showJournalText, setShowJournalText] = useState(true);
  const [showBillingText, setShowBillingText] = useState(true);
  const [showCustomizationText, setShowCustomizationText] = useState(true);

  const navigate = useNavigate();

  const handleLearnMore = (path) => {
    navigate(path);
  };

  const toggleText = () => setShowAdvancedText(!showAdvancedText);
  const toggleJournalText = () => setShowJournalText(!showJournalText);
  const toggleBillingText = () => setShowBillingText(!showBillingText);
  const toggleCustomizationText = () =>
    setShowCustomizationText(!showCustomizationText);

  return (
    <>
      {/* <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW="80vw" // Maximum width: 80% of the viewport width
          maxH="80vh" // Maximum height: 80% of the viewport height
          width="80vw" // Set width to 80% of the viewport width
          height="80vh" // Set height to 80% of the viewport height
          className={isOpen ? "modal-open" : "modal-close"}
        >
          <ModalCloseButton
            position="fixed" // Make the close button fixed
            top="10px" // Adjust the top position as needed
            right="10px" // Adjust the right position as needed
          />
          <ModalBody overflowY="auto"> */}
      <Flex
        justify="flex-start"
        align="center"
        py={0}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
        >
          <Heading fontSize={{ base: "2xl", md: "4xl" }} mb={4}>
            Take charge of your finances with user-friendly accounting software
          </Heading>
          <Text fontSize="lg" mb={6}>
            All the necessary tools for managing cash flow are in one place,
            allowing you to record transactions, summarize, and analyze
            financial reports—making bookkeeping easy.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
        border={showAdvancedText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showAdvancedText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={accountsdashboard}
            alt="Accounting Dashboard"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Gain instant access to your accounting records
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showAdvancedText
              ? "Allow AI to track your performance through provide deep and real-time insights - you can trigger this on the go with just voice instructions over Whatsapp!"
              : "Open source accounting dashboards display all key performance indicators at a glance. With configurable dimensions and customizable layouts, you can analyze every aspect of your business in one place."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showAdvancedText}
                onChange={toggleText}
              />
            </FormControl>
          </Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Chart of accounts
          </Heading>
          <Text fontSize="lg" mb={6}>
            The Chart of Accounts functions as a customizable tree structure for
            organizing your accounting ledgers. It serves as the foundation of
            your company, where ledgers are represented as leaf nodes, and
            groups can include sub-groups and additional ledgers.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={chartofaccounts}
            alt="Chart of Accounts"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
        border={showJournalText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showJournalText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={generalledger}
            alt="Journal and payments"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Journals and Payments
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showJournalText
              ? "Let AI manage your cashflows - recording the transactions, providing hourly summaries and analyzing the financial reports - a dedicated finance professional for near-zero cost"
              : "Maintain an accurate ledger to avoid mistakes. Track all income and expenses easily and manage your cash flow well."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showJournalText}
                onChange={toggleJournalText}
              />
            </FormControl>
          </Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
        border={showBillingText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showBillingText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Billing and Pricing
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showBillingText
              ? "Allow AI to send invoices automatically once the sale is registered -and use the AI companion to do some sneaky competitor analysis on pricing so that you can give the best deal!"
              : "Send invoices easily and follow up with payment reminders via email or SMS. Customize your bills and save time on pricing!"}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showBillingText}
                onChange={toggleBillingText}
              />
            </FormControl>
          </Stack>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={salesinvoice}
            alt="Billing and Pricing"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={multicurrency}
            alt="Multi-currency accounting"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Multi-currency accounting
          </Heading>
          <Text fontSize="lg" mb={6}>
            Manage transactions in multiple currencies easily. Create invoices
            and record expenses in any currency while the system converts them
            for you.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Auto-invoicing
          </Heading>
          <Text fontSize="lg" mb={6}>
            Automate your monthly invoices with our Subscriptions feature for
            recurring orders!
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={autoinvoice}
            alt="Auto-invoicing"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={banking}
            alt="Banking"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Banking
          </Heading>
          <Text fontSize="lg" mb={6}>
            Create as many bank accounts as you need and track balances easily.
            Sync your finances with a simple bank statement upload!
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
        border={showCustomizationText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={
          showCustomizationText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"
        } // Electric glow effect
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            On-The-Fly Customizations
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showCustomizationText
              ? "Allow your AI to customize your accounting software with voice commands-no coding required"
              : "Customize your accounting software to fit your needs—no coding required!"}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showCustomizationText}
                onChange={toggleCustomizationText}
              />
            </FormControl>
          </Stack>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={customizations}
            alt="On-The-Fly Customizations"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
      </Flex>
      {/* <Box py={20} px={8} bg="gray.100">
        <Heading textAlign="center" fontSize="3xl" mb={12}>
          Features that will keep your accounts balanced
        </Heading>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={10}
          px={{ base: 4, md: 20 }}
        >
          <FeatureBox
            icon={FaChartLine}
            title="Chart of Accounts"
            description="Whatever charts of accounts your organization needs, we've got you covered."
            onLearnMore={() => handleLearnMore("/charts-of-accounts")}
          />
          <FeatureBox
            icon={FaBook}
            title="Journal Entry"
            description="Leave no transaction unrecorded.  Make quick journal entries, difference entries, reverse entries, contra entries, and more"
            onLearnMore={() => handleLearnMore("/journal-entry")}
          />
          <FeatureBox
            icon={FaFileAlt}
            title="Bookkeeping"
            description="Comprehensive, open-source bookkeeping software with the additional power of a full ERP"
            onLearnMore={() => handleLearnMore("/bookkeeping")}
          />
          <FeatureBox
            icon={FaFileInvoiceDollar}
            title="Sales and Purchase Management"
            description="Track inventory levels, replenish stock, and manage sales orders, customers, suppliers, shipments, deliverables, and order fulfillment"
            onLearnMore={() => handleLearnMore("/order-management")}
          />
        </SimpleGrid>
      </Box> */}
      {/* <ChartOfAccounts
        isOpen={openModal === "ChartofAccounts"}
        onClose={handleModalClose}
        title="ChartofAccounts"
      />
      <JournalEntry
        isOpen={openModal === "JournalEntry"}
        onClose={handleModalClose}
        title="JournalEntry"
      />
      <Bookkeeping
        isOpen={openModal === "Bookkeeping"}
        onClose={handleModalClose}
        title="Bookkeeping"
      /> */}
      {/* <OrderManagement
        isOpen={openModal === "SalesandPurchaseManagement"}
        onClose={handleModalClose}
        title="SalesandPurchaseManagement"
      /> */}
      {/* </ModalBody>
        </ModalContent>
      </Modal> */}
    </>
  );
};

const FeatureBox = ({ icon, title, description, onLearnMore }) => {
  // Intersection Observer for triggering animations
  const { ref, inView } = useInView({
    triggerOnce: false, // Only animate once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });

  return (
    <MotionBox
      ref={ref}
      textAlign="center"
      p={6}
      bg="white"
      borderRadius="lg"
      boxShadow="lg"
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}} // Animate to visible state when in view
      transition={{ duration: 0.5 }}
      whileHover={{
        scale: 1.05,
        backgroundColor: "#f0f4ff",
        boxShadow: "xl",
      }}
    >
      <Icon as={icon} w={12} h={12} mb={4} color="teal.500" />
      <Heading fontSize="2xl" mb={2} fontWeight="bold" color="gray.800">
        {title}
      </Heading>
      <Text mb={4} fontSize="lg" color="gray.600">
        {description}
      </Text>
      <Button
        variant="solid"
        colorScheme="teal"
        size="lg"
        onClick={onLearnMore}
      >
        Learn more →
      </Button>
    </MotionBox>
  );
};

export default FinancialAccounting;
