import React from "react";
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  Divider,
  List,
  ListItem,
  ListIcon,
  Button,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

const PricingPlan = ({ title, price, details, features }) => {
  const discountedPrice = price * 0.5; // Calculate 50% off

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={6}
      boxShadow="md"
      bg="white"
      maxW="sm"
      textAlign="center"
    >
      <Heading size="md" mb={4} color="black">
        {title}
      </Heading>
      <Text fontSize="xl" color="gray.500" textDecoration="line-through">
        ₹{price}
      </Text>
      <Text fontSize="2xl" fontWeight="bold" mb={4} color="black">
        ₹{discountedPrice.toFixed(2)}
      </Text>
      <Text fontSize="sm" fontWeight="bold" color="green.600" mb={4}>
        50% Flat Off
      </Text>
      <Text fontSize="sm" color="black" mb={4}>
        {details}
      </Text>
      <Divider mb={4} />
      <List spacing={3} textAlign="left">
        {features.map((feature, index) => (
          <ListItem key={index} color="black">
            <ListIcon as={CheckCircleIcon} color="green.500" />
            {feature}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const Pricing = () => {
  const plans = [
    {
      title: "Business Essentials",
      price: 500,
      details:
        "Get comprehensive enterprise management services for finance, sales, and operations",
      features: [
        "Access to all features from our catalogue",
        "Unlimited tasks, unlimited users",
        "24x7 support",
        "Real-time reporting",
        "Dedicated hosting / maintenance",
      ],
    },
    {
      title: "Business Premium",
      price: 2998,
      details:
        "Includes everything in the Essentials plan, plus enhanced AI capabilities to streamline operations",
      features: [
        "Access to all features from our catalogue (incl. AI)",
        "Unlimited tasks, unlimited users",
        "24x7 support",
        "Real-time reporting",
        "Dedicated hosting / maintenance",
        "Custom AI development",
      ],
    },
    {
      title: "Business Team Members",
      price: 300,
      details:
        "Get limited access to read orders, approve workflows, and create or update select information (no AI)",
      features: [
        'Limited edit access; only "read" to all features from our catalogue',
        "Unlimited tasks, unlimited users",
        "24x7 support",
        "Real-time reporting",
        "Dedicated hosting / maintenance",
      ],
    },
  ];

  return (
    <>
      <Box py={10} px={5} bg="blue.800" color="white">
        <Heading mb={2} textAlign="center">
          Our Pricing
        </Heading>
        <Text mb={8} textAlign="center">
          Clear-cut, just like AI - no hidden costs, no limit on features or
          data: enjoy real transparency!
        </Text>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          {plans.map((plan, index) => (
            <PricingPlan
              key={index}
              title={plan.title}
              price={plan.price}
              details={plan.details}
              features={plan.features}
            />
          ))}
        </SimpleGrid>
        <Text fontSize="xs" textAlign="left" mt={4}>
          1. Based on your unique customization requests, the implementation
          fees will vary. Typically, our clients incur between ₹2.5 lacs to ₹4
          lacs for the entire ERP integration.
        </Text>
        <Text fontSize="xs" textAlign="left">
          2. Cost for uniQ hosting not included.
        </Text>
      </Box>

      <Box
        bg="white"
        textAlign="center"
        py={12}
        px={6}
        rounded="lg"
        mx="auto"
        maxW="xl"
        mt={{ base: 5, md: 10 }}
      >
        <Text fontSize="2xl" color="purple.600" fontWeight="bold">
          Need help to decide?
        </Text>
        <Heading as="h2" size="xl" mt={4} fontWeight="bold" color="black">
          Find out which ERP modules are right for you and your business
        </Heading>
        <Text mt={2} color="gray.600">
          Book a call with an expert in less than 30 seconds
        </Text>
        <Button
          mt={8}
          colorScheme="teal"
          variant="solid"
          size="lg"
          onClick={() =>
            (window.location.href = "https://calendly.com/ashish-avapadvisory")
          }
        >
          Help me find an expert now
        </Button>
      </Box>
    </>
  );
};

export default Pricing;
