import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Heading,
  Text,
  Stack,
  Image,
  Flex,
  Switch,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import customizations from "../assets/customize-sales-invoice.png";
import omnichannel from "../assets/omnichannel-interactions.png";
import automatechores from "../assets/automate-daily-chores.png";
import salesefficiency from "../assets/sales-team-efficiency.png";
import salesvisibility from "../assets/sales-visibility.png";
import quotations from "../assets/quotations-print.png";
import digitalmarketing from "../assets/digital-marketing.png";
import "./ModalAnimations.css";

// const CRM = ({ isOpen, onClose, title }) => {
const CRM = () => {
  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.classList.add("no-scroll");
  //   } else {
  //     document.body.classList.remove("no-scroll");
  //   }
  //   return () => {
  //     document.body.classList.remove("no-scroll");
  //   };
  // }, [isOpen]);

  const [showAdvancedText, setShowAdvancedText] = useState(true);
  const [showPipelineText, setShowPipelineText] = useState(true);
  const [showQuotationsText, setShowQuotationsText] = useState(true);

  const toggleText = () => setShowAdvancedText(!showAdvancedText);
  const togglePipelineText = () => setShowPipelineText(!showPipelineText);
  const toggleQuotationsText = () => setShowQuotationsText(!showQuotationsText);

  return (
    <>
      {/* <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW="80vw" // Maximum width: 80% of the viewport width
          maxH="80vh" // Maximum height: 80% of the viewport height
          width="80vw" // Set width to 80% of the viewport width
          height="80vh" // Set height to 80% of the viewport height
          className={isOpen ? "modal-open" : "modal-close"}
        >
          <ModalCloseButton
            position="fixed" // Make the close button fixed
            top="10px" // Adjust the top position as needed
            right="10px" // Adjust the right position as needed
            zIndex="1"
          />
          <ModalBody overflowY="auto"> */}
      <Flex
        justify="flex-start"
        align="center"
        py={0}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          // ref={crmRef}
        >
          <Heading fontSize={{ base: "2xl", md: "4xl" }} mb={4}>
            Close deals faster with the best open-source CRM.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Get more done in less time with uniQ ERP CRM. Generate leads, close
            sales, and automate tasks—all with a customer-focused system.
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          ></Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={omnichannel}
            alt="Omnichannel Interactions"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Manage all customer interactions in one place.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Turn calls, emails, and other interactions into leads without
            switching between apps. Get real-time notifications and stay
            connected with prospects.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Automate routine tasks and free up time for your sales team.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Automate lead distribution with assignment rules, so your team can
            focus on what matters—responding to customers.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={automatechores}
            alt="Automate Daily Chores"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
        border={showAdvancedText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showAdvancedText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={salesefficiency}
            alt="Sales Team Efficiency"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Boost your sales team's productivity.
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showAdvancedText
              ? "Let AI track your team's performance and provide personalized reports on demand to identify areas of improvement / collaboration"
              : "Track your sales team's performance with built-in reports, monitor leads, and collaborate to close more deals."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showAdvancedText}
                onChange={toggleText}
              />
            </FormControl>
          </Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
        border={showPipelineText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showPipelineText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Get a real-time view of your sales pipeline.
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showPipelineText
              ? "Let AI create optimal strategies to improve conversation rates"
              : "Gain total visibility into your sales pipeline and optimize strategies to improve conversion rates."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showPipelineText}
                onChange={togglePipelineText}
              />
            </FormControl>
          </Stack>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={salesvisibility}
            alt="Total Visibility Of Sales Pipeline"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
        border={showQuotationsText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={
          showQuotationsText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"
        } // Electric glow effect
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={quotations}
            alt="Crisp, Beautiful Quotations"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Create professional and polished quotations.
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showQuotationsText
              ? "Utilize our AI's market research skills to identify market quotations / templates to improve your offerings"
              : "Design beautiful, consistent quotations with customizable templates and one-time setup."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showQuotationsText}
                onChange={toggleQuotationsText}
              />
            </FormControl>
          </Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Supercharge your marketing with automated campaigns.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Run targeted email campaigns, send newsletters, and analyze lead
            sources for better marketing results.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={digitalmarketing}
            alt="Digital Marketing"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={customizations}
            alt="On-The-Fly Customizations"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            On-The-Fly Customizations
          </Heading>
          <Text fontSize="lg" mb={6}>
            Customize your accounting software to fit your needs—no coding
            required!
          </Text>
        </Box>
      </Flex>
      {/* </ModalBody>
        </ModalContent>
      </Modal> */}
    </>
  );
};

export default CRM;
