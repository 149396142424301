import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Stack,
  Image,
  Flex,
  SimpleGrid,
  Icon,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"; // Import Framer Motion for animations
import { useInView } from "react-intersection-observer"; // For triggering animations when in view
import recruitment from "../assets/recruitment.webp";
import lifecycle from "../assets/employee_lifecycle.webp";
import attendance from "../assets/attendance.webp";
import leave from "../assets/leave.webp";
import expense from "../assets/expense_management.webp";
import performance from "../assets/performance_management.webp";
import payroll from "../assets/payroll.webp";
import payrolltax from "../assets/payroll_tax.webp";
import "./ModalAnimations.css";

const MotionBox = motion(Box);
// const FinancialAccounting = ({ isOpen, onClose, title }) => {
const HRPayroll = () => {
  const navigate = useNavigate();

  const handleLearnMore = (path) => {
    navigate(path);
  };
  return (
    <>
      <Flex
        justify="flex-start"
        align="center"
        py={0}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
        >
          <Heading fontSize={{ base: "2xl", md: "4xl" }} mb={4}>
            Take control of your HR processes with an intuitive automation
            software
          </Heading>
          <Text fontSize="lg" mb={6}>
            Access all the key tools for managing employee records, payroll,
            attendance, and performance in one platform. Streamline workflows,
            track vital HR data, and generate insightful reports—making HR
            management more efficient and effective.
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          ></Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={recruitment}
            alt="Recruitment"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Recruitment
          </Heading>
          <Text fontSize="lg" mb={6}>
            With a growing company, comes a rapidly growing roster of employees.
            From planning your hiring strategy and scheduling interviews to
            identifying the best candidates and making job offers, uniQ ERP tool
            has got you covered throughout your recruitment cycle.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Employee Lifecycle
          </Heading>
          <Text fontSize="lg" mb={6}>
            We understand that as an HR rep, your goal is to make life easier
            for your employees throughout their entire lifecycle. Right from
            onboarding to exits, transfers to promotions, we have got your back
            every step of the way.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={lifecycle}
            alt="Employee Lifecycle"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={attendance}
            alt="Shifts & Attendance"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Shifts & Attendance
          </Heading>
          <Text fontSize="lg" mb={6}>
            Managing attendance for a geographically scattered team can be a
            nightmare. Bid adieu to tedious spreadsheets and shift registers.
            uniQ ERP tool offers a variety of options to ensure that attendance
            records are always up-to-date. Our mobile app enables your team to
            clock in and out from anywhere with geolocation capturing. You can
            also manage shifts and rosters effortlessly and integrate your
            biometric device for auto attendance. Need more control? The bulk
            attendance & shift tools help you efficiently manage attendance at
            scale.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Leave Management
          </Heading>
          <Text fontSize="lg" mb={6}>
            uniQ ERP tool makes allocating, applying, tracking & managing leaves
            a breeze. Configure even the most complex leave policies & rules
            with ease. Carry-forwarding, expiry, leave without pay, earned
            leaves - it's all covered. Manage bulk leave policy assignments.
            Visualize and plan work efficiently with the leave calendar. View
            leave balances, apply & approve leaves - all from your phone. Keep
            your team informed with configurable workflows and notifications.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={leave}
            alt="Leave Management"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={expense}
            alt="Expense Management"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Expense Management
          </Heading>
          <Text fontSize="lg" mb={6}>
            Managing expenses can be a daunting task for organizations, often
            bogged down by endless paperwork, manual data entry, and tedious
            approval processes. Streamline travel and expense management. Payout
            advances, track claims, and draw meaningful insights with the
            seamless accounting integration.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Performance Management
          </Heading>
          <Text fontSize="lg" mb={6}>
            Effective performance management is key to unlocking your team's
            full potential and achieving organizational goals. uniQ ERP tool
            offers a comprehensive system that empowers you to define
            department-wise Key Result Areas (KRAs) and individual goals, track
            progress, and provide continuous employee feedback. Embrace a more
            organized, growth-focused approach with features like appraisal
            templates, automated goal score computation, formula-based final
            scores, and more.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={performance}
            alt="Performance Management"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={payroll}
            alt="Payroll"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Payroll
          </Heading>
          <Text fontSize="lg" mb={6}>
            Seeing a 'salary credited; message often brings a smile to your
            face, but there's a lot of work that goes into that. Diverse pay
            structures, complex regional tax regulations, and accurate
            generation of payslips for a large team can feel overwhelming. We're
            making all of this easier with a feature-packed payroll module. Cut
            ties with disparate payroll and accounting systems to save hours of
            reconciliation work every month on payroll and expense data. Frappe
            HR promises integrated accounting with your payroll.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={{ base: 10, md: 20 }}
        px={{ base: 4, md: 8 }}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={{ base: 5, md: 10 }}
          px={{ base: 3, md: 6 }}
          maxW={{ base: "100%", md: "50%" }}
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Payroll Tax & Reports
          </Heading>
          <Text fontSize="lg" mb={6}>
            uniQ ERP tool flexibility enables you to map tax configurations for
            any region. Define income tax slabs, manage tax exemptions, and
            proof submissions. Stay informed throughout your payroll period with
            tax breakups on salary slips and the income tax computation report.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={payrolltax}
            alt="Payroll Tax & Reports"
            borderRadius="md"
            boxShadow="lg"
            maxW={{ base: "300px", md: "600px" }}
            objectFit="cover"
          />
        </Box>
      </Flex>
    </>
  );
};

const FeatureBox = ({ icon, title, description, onLearnMore }) => {
  // Intersection Observer for triggering animations
  const { ref, inView } = useInView({
    triggerOnce: false, // Only animate once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });

  return (
    <MotionBox
      ref={ref}
      textAlign="center"
      p={6}
      bg="white"
      borderRadius="lg"
      boxShadow="lg"
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}} // Animate to visible state when in view
      transition={{ duration: 0.5 }}
      whileHover={{
        scale: 1.05,
        backgroundColor: "#f0f4ff",
        boxShadow: "xl",
      }}
    >
      <Icon as={icon} w={12} h={12} mb={4} color="teal.500" />
      <Heading fontSize="2xl" mb={2} fontWeight="bold" color="gray.800">
        {title}
      </Heading>
      <Text mb={4} fontSize="lg" color="gray.600">
        {description}
      </Text>
      <Button
        variant="solid"
        colorScheme="teal"
        size="lg"
        onClick={onLearnMore}
      >
        Learn more →
      </Button>
    </MotionBox>
  );
};

export default HRPayroll;
